import React from 'react';

// Materia Ui
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { blueGrey } from '@mui/material/colors';
import ListItem from '@mui/material/ListItem';
import Slide from '@mui/material/Slide';
import List from '@mui/material/List';

// CSS
import Styles from './Sidebar.module.css';

// Language
// import Language from '../Language/Language';

// Images
import Logo from '../../assets/imgs/Navbar/Logo.png';

// Cross Icon
import CancelIcon from '@mui/icons-material/Cancel';

// Material Ui
import Link from '@mui/material/Link';

const Sidebar = ({ isOpen, toggle }) => {

    return (
        <div>
            <Slide in={isOpen} direction='down'>
                <section 
                    className={Styles.SidebarContainer} 
                    id='Sidebar'
                >
                    <div className={Styles.LogoContainer}>
                        <div className={Styles.CloseMenu}>
                            <CancelIcon 
                                sx={{ color: blueGrey[50] }} 
                                fontSize='large' 
                                onClick={toggle}
                            />
                        </div>
                        <a href="/">
                            <img src={Logo} alt="Logo" />
                        </a>
                    </div>
                    <div className={Styles.RoutesSidebar}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="/about">
                                    <ListItemText
                                        className={Styles.ItemText} 
                                        primary="About Us" 
                                        sx={{ color: blueGrey[50] }} 
                                        align="center"
                                    />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="/whatwedo">
                                    <ListItemText 
                                        className={Styles.ItemText}
                                        primary="Our services" 
                                        sx={{ color: blueGrey[50] }}
                                        align="center"
                                    />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="/howwework">
                                    <ListItemText 
                                        className={Styles.ItemText}
                                        primary="Our Guidelines" 
                                        sx={{ color: blueGrey[50] }}
                                        align="center"
                                    />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Link href='/contact' className={Styles.ButtonContactSidebar}>Contact us</Link>
                        {/* <List>
                            <ListItem>
                            <div className={Styles.LanguageSidebar}>
                                <Language/>
                            </div>
                            </ListItem>
                        </List>  */}
                    </div>
                </section>
            </Slide>
        </div>
    )
}

export default Sidebar
