import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

// Components
import Sidebar from './components/Sidebar/Sidebar';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';

// Pages
import WhatWeDo from './pages/WhatWeDo/WhatWeDo';
import AboutUs from './pages/AboutUs/AboutUs';
import HowWeWork from './pages/HowWeWork/HWW'; 
import Contact from './pages/Contact/Contact'; 
import Home from './pages/Home/Home';


const App = () =>  {

  const [isOpen, setIsOpen] = useState(false)
  
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <BrowserRouter>
      <Navbar toggle={toggle}/>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Routes>
        <Route path='/' exact element={<Home />}/>
        <Route path='/about' exact element={<AboutUs />}/>
        <Route path='/contact' exact element={<Contact />}/>
        <Route path='/whatwedo' exact element={<WhatWeDo />}/>
        <Route path='/howwework' exact element={<HowWeWork />}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
