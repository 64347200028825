import React from 'react';

// CSS
import Styles from './ContactItem.module.css';

const ContactItem = (props) => {
    return (
        <div className={Styles.ContactItemContainer}>
            <img src={props.src} alt="Item logo"/>
            <h2>{props.title}</h2>
            <p>{props.description}</p>
        </div>
    )
}

export default ContactItem
