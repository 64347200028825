import React from 'react';

// CSS
import Styles from './WhatWeDo.module.css';

// Components
import SliderWWD from '../../components/WhatWeDo/SliderWWD/SliderWWD';
import CardsWWD from '../../components/WhatWeDo/CardsWWD/CardsWWD';

// Material ui 
import Link from '@mui/material/Link';

// Images
import WhatsAppImg from '../../assets/imgs/WhatWeDo/WhatsAppImg.png';

// Flowy imgs 
import FlowyLogo from '../../assets/imgs/WhatWeDo/SliderWWD/Flowy/FlowyLogo.png';

// WhatsPRO imgs
import WhatsLogo from '../../assets/imgs/WhatWeDo/SliderWWD/WhatsPRO/WhatsLogo.png';

// Woki Mobile imgs
import WokiMobileLogo from '../../assets/imgs/WhatWeDo/SliderWWD/Woki/WokiMobileLogo.png';

// Woki Partner imgs 
import WokiPartnerLogo from '../../assets/imgs/WhatWeDo/SliderWWD/WokiPartner/WokiPartnerLogo.png';

const WhatWeDo = () => {
    return (
        <>
            <section className={Styles.HeroWWD}>
                <div className={Styles.HeroWWDTexts}>
                    <h1>What we do</h1>
                    <p>In each of our projects, we seek excellence, using all the necessary tools to achieve our goals, within the established time and budget limits. Our team is composed by SR, SSR, JR and Trainee Engineers, Analysts, Programmers, Graphic Designers, UI / UX teams, QA Testers and DevOps. They work proactively with professional advisers dedicated to solving issues related to financial, educational, accounting, legal activity, administrative and marketing matters. </p>
                </div>
            </section>
            <section className={Styles.CardsAboutUsContainer}>
                    <CardsWWD
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FCardsImg%2FChart.png?alt=media&token=2fafd9b3-da01-4461-8265-04c7d0f3e5fd'
                        }
                        title={"Business Analysis"}
                        description={
                            "Our team analyzes your idea´s functionality, business logic, software architecture and future user scenarios."
                        }
                    />
                    <CardsWWD
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FCardsImg%2FBox.png?alt=media&token=9b40d9b5-925c-4fbc-83c2-d0360adb46cd'
                        }
                        title={
                            "Product Development"
                        }
                        description={
                            "We build the solution step by step, with ongoing quality assurance."
                        }
                    />
                    <CardsWWD
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FCardsImg%2FShieldBlue.png?alt=media&token=4d827ddc-c426-4d33-bded-93bd2c2020fc'
                        }
                        title={
                            "Quality Assurance"
                        }
                        description={
                            "Our Quality Assurance and Production teams work separately. We conduct thorough and rigorous QA checks on all of our projects."
                        }
                    />
                    <CardsWWD
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FCardsImg%2FSetting.png?alt=media&token=2122ef3f-2337-4c5a-9214-4889fe52f4b9'
                        }
                        title={
                            "Maintenance"
                        }
                        description={
                            "Our team monitors the solution, provides support and works on the updates proposed for the system."
                        }
                    />
                    <CardsWWD
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FCardsImg%2FCloud.png?alt=media&token=a52e3d5c-9928-49cd-be50-47a52e34b2be'
                        }
                        title={
                            "Cloud Architecture"
                        }
                        description={
                            "Adopting this method we accelerate the delivery of new projects, operate with transparency in order to cut costs and prevent data breaches and offer scalability according to the requirements of your business."
                        }
                    />
                    <CardsWWD
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FCardsImg%2FDesktop.png?alt=media&token=79d9e695-0999-48db-83fa-119b626ddd61'
                        }
                        title={
                            "UI - UX Design"
                        }
                        description={
                            "We design intuitive interfaces for a quick adaptation of the system, showing the best of your company. "
                        }
                    />
            </section>
            <main className={Styles.OurWorkContainer}>
                <h1>Our Work</h1>
                <div className={Styles.OurWorkSliderContainer}>
                    <SliderWWD 
                        marginTop={"2rem"}
                        srcIcon={FlowyLogo}
                        description={
                            "Flowy´s optimization algorithm processes your store's selling record identifying your buyers patterns and products affinity. This way it identifies those products which have been repetitively bought together and suggests you the offer. All you have to do is accept it."
                        }
                        src={[
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FFlowyCarousel%2FFlowy.png?alt=media&token=b9f3ffcd-ca4f-47f7-a83e-f2770b3ab950', 
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FFlowyCarousel%2FFlowyImg1.png?alt=media&token=6bf07497-0861-462f-b6c4-3b5e63ec606e', 
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FFlowyCarousel%2FFlowyImg2.png?alt=media&token=aa24b670-7df2-4cad-9506-d82995e1dd80', 
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FFlowyCarousel%2FFlowyImg3.png?alt=media&token=54621f46-3e91-49e5-827e-128f4204c809',
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FFlowyCarousel%2FFlowyImg4.png?alt=media&token=2ee1be5e-5b88-43af-b6f0-fe695310b0d0'
                        ]}
                    />
                </div>
                <div className={Styles.OurWorkSliderContainer}>
                    <SliderWWD 
                        marginTop={"2rem"}
                        srcIcon={WhatsLogo}
                        description={
                            "WhatsPRO is the perfect plugin for your Tienda Nube. It allows you to respond instantly to all your frequent questions without the need to leave the store page. Further, it lets you add simultaneous multiple WhatsApp users to be in contact with your customers."
                        }
                        src={[
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWhatsCarousel%2FWhatsPro.png?alt=media&token=49ae6544-50a9-466a-8771-8ecacdafef88', 
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWhatsCarousel%2FWhatsProImg1.png?alt=media&token=acac3951-7a16-41a8-aa54-6553e8fdb38a', 
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWhatsCarousel%2FWhatsProImg2.png?alt=media&token=3103a3c0-1855-41fa-93e1-fc6eb9da2ca9', 
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWhatsCarousel%2FWhatsProImg3.png?alt=media&token=823dd32b-1fbc-464b-9951-23bf508b2c1f'
                        ]}
                    />
                </div>
                <div className={Styles.OurWorkSliderContainer}>
                    <SliderWWD 
                        marginTop={"2rem"}
                        srcIcon={WokiMobileLogo} 
                        description={
                            "This is the customers favorite App for going out to eat. Get yourself a table in your beloved restaurants. Book with a few clicks, check the status of your reservation or modify it anywhere, anytime."
                        }
                        src={[
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWokiMobileCarousel%2FWoki.png?alt=media&token=9829f58c-ccf7-4d32-80b5-ca0f2eee9be8', 
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWokiMobileCarousel%2FWokiMobile1.png?alt=media&token=fa89db9e-fb6e-45f1-82f0-8df854e5d6fc', 
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWokiMobileCarousel%2FWokiMobile2.png?alt=media&token=846e4166-1a47-417f-a6e1-f91e49aa9827', 
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWokiMobileCarousel%2FWokiMobile3.png?alt=media&token=b373ab5c-57b0-45a6-8130-7935bcf250b7'
                        ]}
                    />
                </div>
                <div className={Styles.OurWorkSliderContainer}>
                    <SliderWWD 
                        marginTop={"2rem"}
                        srcIcon={WokiPartnerLogo} 
                        description={
                            "Manage your restaurant's reservations agenda and organize your table layout.  Keep your customers´ information on hand and pull marketing strategies according to it."
                        }
                        src={[
                                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWokiPartnerCarousel%2FWokiPartner.png?alt=media&token=770a1dc2-98ae-479a-b991-a56bc720da3c',
                                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWokiPartnerCarousel%2FWokiPartnerImg1.png?alt=media&token=081ebc3a-775a-49b9-a2e4-f0716999dde0',
                                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWokiPartnerCarousel%2FWokiPartnerImg2.png?alt=media&token=7ab8ff16-546f-48d3-8a0c-a8c9335d21f1',
                                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWokiPartnerCarousel%2FWokiPartnerImg3.png?alt=media&token=094d0d06-9e72-439d-b1da-b0956ce2df4c',
                                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FWokiPartnerCarousel%2FWokiPartnerImg4.png?alt=media&token=7cbc6903-9672-41a2-a047-b3b5b6566998'
                        ]}
                    />
                </div>
            </main>
            <section className={Styles.CompaniesContainer}>
                <h1>Companies that trust us</h1>
                <p>Companies and clients with whom we carry out joint work and who trust our services</p>
                <div className={Styles.CompaniesLogos}>
                    <img 
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FLogosCompanies%2FTNImg.png?alt=media&token=bc42ee0d-3966-4a55-ba50-3a75ed1ec9bc'
                        } 
                        alt="Logos Companies" 
                    />
                    <img 
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FLogosCompanies%2FCabralesImg.png?alt=media&token=23ef409b-2b15-499e-a796-8a86f4259897'
                        } 
                        alt="Logos Companies" 
                    />
                    <img 
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FLogosCompanies%2FZasImg.png?alt=media&token=9231e4eb-3784-456f-b8e1-7110c80335ad'
                        } 
                        alt="Logos Companies" 
                    />
                    <img 
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FLogosCompanies%2FParquePapasImg.png?alt=media&token=6cf409b5-1a2e-4188-a4a7-57fa647c4d57'
                        } 
                        alt="Logos Companies"
                    />
                    <img 
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FWhatWeDo%2FLogosCompanies%2FVitalisImg.png?alt=media&token=4f1ed2f1-39ac-460e-b672-5ca50a0df3c3'
                        } 
                        alt="Logos Companies" 
                    />
                </div>
            </section>
            <section className={Styles.ContractSection}>
                <h1>To contract our services</h1>
                <Link href='https://wa.me/2235991811' className={Styles.ContractSectionButton}>
                    <img src={WhatsAppImg} alt="WhatsAppLogo" />
                    <h3>Chat with us!</h3>
                </Link>
            </section>
        </>
    )
}

export default WhatWeDo
