import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

// Language
// import Language from '../Language/Language';

// CSS
import Styles from './Navbar.module.css';

//Material Ui
import useScrollTrigger from '@mui/material/useScrollTrigger';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';

// Images
import Logo from '../../assets/imgs/Navbar/Logo.png';


const Navbar = ({props, toggle}) => {
    // hww: How we work link
    // wwd: What we do link
    // about: About us link
    const [about, setAbout] = useState(false);
    const [wwd, setWwd] = useState(false);
    const [hww, setHww] = useState(false);
    const HideOnScroll = (props) => {
        const { children, window } = props;
        const trigger = useScrollTrigger({
            target: window ? window() : undefined,
        });
        
        return (
            <Slide appear={false} direction="down" in={!trigger}>
                {children}
            </Slide>
        );
    };

    const location = useLocation();
    useEffect(() => {
        switch (location.pathname) {
            case '/about':
                setAbout(true);
                setHww(false);
                setWwd(false);
                break;
            case '/whatwedo':
                setWwd(true);
                setAbout(false);
                setHww(false);
                break;
            case '/howwework':
                setHww(true);
                setWwd(false);
                setAbout(false);
                break;
            default:
                setHww(false);
                setWwd(false);
                setAbout(false);
                break;
        }
    }, [location]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <HideOnScroll {...props}>
            <AppBar className={Styles.NavbarContainer}>
                <Toolbar className={Styles.ToolbarNavbar}>
                    <Link to="/">
                        <img src={Logo} alt="Logo"/>
                    </Link>
                    <IconButton
                        className={Styles.MobileButton}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={toggle}
                        >
                        <MenuIcon />
                    </IconButton>
                    <div className={Styles.Links}>
                        <div className={Styles.LinksMiddle}>
                            <Link 
                                className={about ? Styles.LinkNavbarActive : Styles.LinkNavbar}
                                to="/about"
                            >
                                About Us
                            </Link>
                            <Link 
                                className={wwd ? Styles.LinkNavbarActive : Styles.LinkNavbar} 
                                to="/whatwedo"
                            >
                                Our Services
                            </Link>
                            <Link 
                                className={hww ? Styles.LinkNavbarActive : Styles.LinkNavbar} 
                                to="/howwework"
                            >
                                Our Guidelines
                            </Link>
                        </div>
                        <div className={Styles.LinksRight}>
                            <Link 
                                to='/contact' 
                                className={Styles.ButtonContact}
                            >Contact us</Link>
                            {/* <Language/> */}
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            </HideOnScroll>
        </Box>
    )
}

export default Navbar
