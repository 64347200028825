import React from 'react';

// CSS
import Styles from './SliderHWW.module.css';

// Carousal
import { Carousel } from '3d-react-carousal';

const SliderHWW = () => {

    let slides = [
        <div>
            <img 
            src={
                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FCarousel3D%2FAWSImg.png?alt=media&token=3f8db5ea-f99b-4fc7-bbee-3040181d65ac'
            } 
            alt='Screen'/>
            <p>IaaS AWS - Terraform</p>
        </div>
        ,
        <div>
            <img 
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FCarousel3D%2FBitbucketImg.png?alt=media&token=8bbba8cc-6b5d-45b2-b83f-8e67be5c23cf'
                } 
                alt='Screen'
            />
            <p>Jira Software - Confluence</p>
        </div>,
        <div>
        <img 
            src={
                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FCarousel3D%2FFigmaImg.png?alt=media&token=5595f424-086e-44ef-abae-22769487589c'
            } 
            alt='Screen'
        />
        <p>UI/UX - Figma</p>
        </div>,
        <div>
        <img 
            src={
                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FCarousel3D%2FFirebaseImg.png?alt=media&token=1ffdd2ca-6830-4989-83ab-756640c23f52'
            } 
            alt='Screen'
        />
        <p>SaaS Firebase</p>
        </div>,
        <div>
        <img 
            src={
                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FCarousel3D%2FTimeDoctorImg.png?alt=media&token=6d2ee488-3f6f-466b-a6ea-591ce814e2f7'
            } 
            alt='Screen'
        />
        <p>Time tracking - Time Doctor</p>
        </div>,
    ]

    return (
        <div className={Styles.SliderHWWContainer}>
            <Carousel slides={slides} autoplay={false} intervals={1000} arrows={true}/>
        </div>
    )
}

export default SliderHWW
