import React from 'react';

const TechnologiesItem = (props) => {
    return (
        <img
            data-aos="flip-left"
            data-aos-duration={props.duration}
            src={props.src} 
            alt='Logo Tech'
        />
    );
};

export default TechnologiesItem;
