import Slider from "react-slick";
import React from 'react';

// CSS
import Styles from './SliderWWD.module.css';


const SliderWWD = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className={Styles.SliderWWDContainer}>
            <div className={Styles.SliderWWDtitle}>
                <img src={props.srcIcon} alt="Logo" />
            </div>
            <h1>{props.title}</h1>
            <Slider {...settings} className="Slide-WWD">
                {props.src.map(
                    (img, i) => <img src={img} alt="Projects" key={i} style={{borderRadius: '20px !important'}}/>
                )}
            </Slider>
            <p style={{marginTop: `${props.marginTop}`}}>{props.description}</p>
        </div>
    )
}

export default SliderWWD
