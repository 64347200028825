import React from 'react';

// CSS
import Styles from './PeopleItem.module.css';

const PeopleItem = (props) => {
    return (
        <div data-aos="fade-down" data-aos-duration="1000" className={Styles.PeopleItemContainer}>
            <img src={props.src} alt="Team profile" />
            <h3>{props.name}</h3>
            <p>{props.job}</p>
        </div>
    )
}

export default PeopleItem
