import React from 'react';

// CSS
import Styles from './HWW.module.css';

// Components
import HowWeWorkProcessItem from '../../components/HowWeWork/HWWProcess/HWWProcess';
import Technologies from '../../components/Home/Technologies/Technologies';
import Carousel3D from '../../components/Sliders/SliderHWW/SliderHWW';

// Images
import SettingsWhiteImg from '../../assets/imgs/HowWeWork/SettingsWhiteImg.png';
import DoubleArrowImg from '../../assets/imgs/HowWeWork/DoubleArrowImg.png';
import CubeWhiteImg from '../../assets/imgs/HowWeWork/CubeWhiteImg.png';
import HWWImage1 from '../../assets/imgs/Home/HowWeWork/HWWImage1.png';
import HWWImage2 from '../../assets/imgs/Home/HowWeWork/HWWImage2.png';
import LupaImg from '../../assets/imgs/HowWeWork/LupaImg.png';

const HWW = () => {
    return (
        <>
            <section className={Styles.HeroHWW}>
                <div className={Styles.HeroHWWContents}>
                    <div className={Styles.HeroHWWContentsTitle}>
                        <h1>Our Process</h1>
                    </div>
                    <div className={Styles.HeroHWWProcess}>
                        <HowWeWorkProcessItem
                            src={HWWImage1}
                            title={"Business Analysis"}
                            description={
                                "Our team analyze your project's functionality, business logic, software architecture, and future user scenarios."
                            }
                        />
                        <HowWeWorkProcessItem
                            src={HWWImage2}
                            title={"UI - UX Design & Prototype"}
                            description={
                                "We design intuitive interfaces for a quick adaptation of the system, showing the best of your company."
                            }
                        />
                        <HowWeWorkProcessItem
                            src={CubeWhiteImg}
                            title={"Development"}
                            description={
                                "We build the solution step by step, with ongoing quality assurance."
                            }
                        />
                        <HowWeWorkProcessItem
                            src={LupaImg}
                            title={"Testing"}
                            description={
                                "We determine together that the software works as it should."
                            }
                        />
                        <HowWeWorkProcessItem
                            src={DoubleArrowImg}
                            title={"Deployment & Product Delivery"}
                            description={
                                "We work with your team to launch the system for your organization."
                            }
                        />
                        <HowWeWorkProcessItem
                            src={SettingsWhiteImg}
                            title={"Maintenance, Support & Updates"}
                            description={
                                "Our team monitors the solution, provides support and works on the updates proposed for the system."
                            }
                        />
                    </div>
                </div>
            </section>
            <section className={Styles.MethodologiesContainer}>
                <h1>We use Agile Methodologies</h1>
                <img 
                    src={
                        'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FMethodologies.png?alt=media&token=5015796f-d95e-4f36-a47c-67b4c8328434'
                    } 
                    alt="Methodologies" 
                />
            </section>
            <main className={Styles.WeWork}>
                <div className={Styles.WeWorkContainer}>
                    <div className={Styles.WeWorkTexts}>
                        <h2>We work by your side</h2>
                        <p>During all stages of an idea´s process, we maintain constant communication with our clients, making them part of the whole procedure. Consequently, they get to monitor progress, provide enriching feedback minimizing costs. This communication structure is composed of the best tools on project management and monitoring.</p>
                    </div>
                    <div className={Styles.WeWorkIcons}>
                        <img 
                            src={
                                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FIcons%2FFigmaIcon.png?alt=media&token=6a6aff31-77e6-4a9c-b59f-5e60a04fdc2e'
                            } 
                            alt="Logo"
                        />
                        <img 
                            src={
                                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FIcons%2FBitbucketIcon.png?alt=media&token=c1c26ca7-4256-4ecc-9af4-389422e4f6b6'
                            } 
                            alt="Logo"
                        />
                        <img 
                            src={
                                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FIcons%2FFirebaseIcon.png?alt=media&token=391a38af-a97d-40d4-a28f-8c3c32992414'
                            } 
                            alt="Logo"
                        />
                        <img 
                            src={
                                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FIcons%2FSlackIcon.png?alt=media&token=64ed8f58-7fe2-4329-bcff-cb50eed84912'
                            } 
                            alt="Logo"
                        />
                        <img 
                            src={
                                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FIcons%2FTimeDoctorIcon.png?alt=media&token=addf9754-335f-45d7-93ac-7dd3e589404d'
                            } 
                            alt="Logo"
                        />
                        <img 
                            src={
                                'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHowWeWork%2FIcons%2FLinesIcon.png?alt=media&token=6cfd828a-490c-4b70-9c74-f55554500c22'
                            } 
                            alt="Logo"
                        />
                    </div>
                </div>
                <div className={Styles.WeWorkScreensContainer}>
                    <Carousel3D/>
                </div>
            </main>
            <section className={Styles.TechnologiesContainer}>
                <h1>Technologies we use</h1>
                <Technologies/>
            </section>
        </>
    )
}

export default HWW
