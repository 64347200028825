import React, { useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import emailjs from 'emailjs-com';

// CSS
import Styles from './Contact.module.css';

// Material Ui
import { inputLabelClasses } from "@mui/material/InputLabel";
import TextField from '@mui/material/TextField';

// Components
import ItemContact from '../../components/Contact/ContactItems/ContactItem'; 
import Toast from '../../components/Alert/Toast/Toast';

// Images
import LupaImg from '../../assets/imgs/HowWeWork/LupaImg.png';

const Contact = () => {
    const [fadeIn, setFadeIn] = useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: "onChange"
    });

    const form = useRef();
    const OnSubmit = (data) => {
        console.log(JSON.stringify(data));
        document.getElementById('contactForm').reset();
        document.getElementById('toast-form').style.display = "block";
        setFadeIn(true);
        setTimeout(() => {
            document.getElementById('toast-form').style.display = "block";
            setFadeIn(false);
        }, 1500);

        emailjs.send(
            'service_76yi4xo', 
            'template_ugq27f6', 
            data, 
            'user_en8fku9oeHybe2jESHJXm'
        ).then( res => {
            console.log(res);
        })
        .catch(
            error => console.log('Error --> ', error)
        );
    };
    
    return (
        <>
            <section className={Styles.ContactContainer}>
                <div className={Styles.ContactContainerTexts}>
                    <h1>Contact us</h1>
                    <div className={Styles.ItemsContainer}>
                        <ItemContact 
                            src={LupaImg} 
                            title={"Availability"} 
                            description={"Contact us and we will respond as soon as possible"}
                        />
                        <ItemContact 
                            src={LupaImg} 
                            title={"Reliability"} 
                            description={"Tell us your idea, and we will indicate the steps to follow to move forward"}
                        />
                        <ItemContact 
                            src={LupaImg} 
                            title={"Flexibility"} 
                            description={"We adapt to your times"}
                        />
                    </div>
                </div>
                <main className={Styles.FormSection}>
                    <Toast 
                        text={'Gracias por comunicarte con nuestro equipo, brevemente nos pondremos en contacto'}
                        in={fadeIn}
                    />
                    <form ref={form} id='contactForm' onSubmit={handleSubmit(OnSubmit)}>
                        <TextField 
                            sx={{ margin: '1rem 0 .5rem 0' }}
                            InputLabelProps={{
                                sx: {
                                    color: "black",
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: "black"
                                    },
                                    fontSize: '18px'
                                }
                            }}
                            type="text"
                            label="Name"
                            name='name' 
                            variant="filled"
                            error={errors.name !== undefined}
                            helperText={errors.name !== undefined ? errors.name.message : ''}
                            {...register(
                                "name", 
                                { 
                                    required: 'This is required', 
                                    pattern: {
                                        value: /^[A-Za-z]{3,16}$/g,
                                        message: 'Length 3 - 16 characters'
                                    }  
                                }
                            )}
                        />
                        <TextField
                            sx={{ margin: '1rem 0 .5rem 0' }}
                            InputLabelProps={{
                                sx: {
                                    color: "black",
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: "black"
                                    },
                                    fontSize: '18px'
                                }
                            }}
                            type="text"
                            label="Surname" 
                            name="Surname"
                            variant="filled" 
                            error={errors.surname !== undefined}
                            helperText={errors.surname !== undefined ? errors.surname.message : ''}
                            {...register(
                                "surname", 
                                { 
                                    required: 'This is required',   
                                }
                            )}
                        />
                        <div className={Styles.EmailPhoneForm}>
                            <TextField
                                sx={{ margin: '1rem 0 .5rem 0' }}
                                InputLabelProps={{
                                    sx: {
                                        color: "black",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: "black"
                                        },
                                        fontSize: '18px'
                                    }
                                }} 
                                type="email"
                                label="Email" 
                                name="Email"
                                variant="filled" 
                                error={errors.email !== undefined}
                                helperText={errors.email !== undefined ? errors.email.message : ''}
                                {...register(
                                    "email", 
                                    { 
                                        required: 'This is required', 
                                        pattern: {
                                            value: /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,4})+$/g,
                                            message: 'The email format is invalid'
                                        }  
                                    }
                                )}
                            />
                            <TextField
                                sx={{ margin: '1rem 0 .5rem 0' }}
                                InputLabelProps={{
                                    sx: {
                                        color: "black",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: "black"
                                        },
                                        fontSize: '18px'
                                    }
                                }}
                                type="tel"
                                name="Phone"
                                label="Phone Number" 
                                variant="filled"
                                error={errors.phone !== undefined}
                                helperText={errors.phone !== undefined ? errors.phone.message : ''}
                                {...register(
                                    "phone", 
                                    { 
                                        required: 'This is required', 
                                        pattern: {
                                            value: /^[0-9]{10,20}$/g,
                                            message: 'Minimum 10 numbers',
                                        },  
                                    }
                                )}
                            />
                        </div>
                        <div className={Styles.MessageForm}>
                            <TextField
                                sx={{ margin: '.5rem 0 .5rem 0' }}
                                InputLabelProps={{
                                    sx: {
                                        color: "black",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: "black"
                                        },
                                        fontSize: '20px'
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        marginBottom: '0px !important'
                                    }
                                }}
                                label="Message"
                                name="Message"
                                multiline
                                rows={4}
                                variant="filled"
                                error={errors.message !== undefined}
                                helperText={errors.message !== undefined && errors.message.type === 'required' ? errors.message.message : ''}
                                {...register(
                                    "message", 
                                    { 
                                        required: <span>This is required</span>, 
                                        maxLength: {
                                            value: 300,
                                            message: 'Max 300 characters'
                                        }  
                                    }
                                )}
                            />
                            <p style={{color: errors.message ? 'red' : 'black'}}>Máx 300 caracteres</p>
                        </div>
                        <input 
                            type="submit" 
                            className={Styles.SendButtonForm}
                            value='SEND'
                        />
                    </form>
                </main>
            </section>
        </>
    )
}

export default Contact
