import React from 'react';

//CSS
import Styles from './Toast.module.css';

//Material Ui
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';

const Toast = (props) => {
    
    return (
        <div id='toast-form' className={Styles.ToastConatiner}>
            <Fade in={props.in}>
                <Alert severity="success" color="info">
                    {props.text}
                </Alert>
            </Fade>
        </div>
    )
}

export default Toast
