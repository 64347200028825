import React from 'react';

// CSS
import Styles from './HowWeWorkItem.module.css';

const HowWeWorkItem = (props) => {
    return (
        <div data-aos="fade-up" data-aos-duration={props.duration} className={Styles.HowWeWorkItem}>
            <img src={props.src} alt="Img How we work"/>
            <div className={Styles.HowWeWorkItemTexts}>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
            </div>
        </div>
    )
}

export default HowWeWorkItem
