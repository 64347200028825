import React from 'react';

// CSS
import Styles from './Footer.module.css'; 

// Material Ui
import Link from '@mui/material/Link';

// Images
import LinkedinImg from '../../assets/imgs/Footer/Linkedin.png';
import FacebookImg from '../../assets/imgs/Footer/Facebook.png';
import PhoneImg from '../../assets/imgs/Footer/Phone.png';
import MapImg from '../../assets/imgs/Footer/Map.png';

const Footer = () => {
    return (
        <footer className={Styles.FooterContainer}>
            <div className={Styles.FooterLinksContainer}>
                <div className={Styles.FooterLinkContainer}>
                    <h2>About Us</h2>
                    <ul>
                        <li>
                            <Link className={Styles.FooterLink} href='/about'>Our history and values</Link>
                        </li>
                        <li>
                            <Link className={Styles.FooterLink} href='/about'>Our team</Link>
                        </li>
                    </ul>
                </div>
                <div className={Styles.FooterLinkContainer}>
                    <h2>Our Services</h2>
                    <ul>
                        <li>
                            <Link className={Styles.FooterLink} href='/whatwedo'>Services</Link>
                        </li>
                        <li>
                            <Link className={Styles.FooterLink} href='/whatwedo'>Our work</Link>
                        </li>
                    </ul>
                </div>
                <div className={Styles.FooterLinkContainer}>
                    <h2>Our Guidelines</h2>
                    <ul>
                        <li>
                            <Link className={Styles.FooterLink} href='/howwework'>Our process</Link>
                        </li>
                        <li>
                            <Link className={Styles.FooterLink} href='/howwework'>Methodologies</Link>
                        </li>
                        <li>
                            <Link className={Styles.FooterLink} href='/howwework'>Technologies</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={Styles.FooterContactContainer}>
                <div className={Styles.FooterContactItem}>
                    <img src={MapImg} alt="Map"/>
                    <p>San Luis 1729 Piso 2 - Mar del Plata</p>
                </div>
                <div className={Styles.FooterContactItem}>
                    <img src={PhoneImg} alt="Phone"/>
                    <a href='tel: 2255533185'>Call us!</a>
                </div>
                <div className={Styles.FooterContactSocial}>
                    <Link href='https://www.facebook.com/' target="_blank">
                        <img src={FacebookImg} alt="Facebook Img"/>
                    </Link>
                    <Link href='https://www.linkedin.com/company/apeirongs/mycompany/' target="_blank">
                        <img src={LinkedinImg} alt="Linkedin Img"/>
                    </Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer
