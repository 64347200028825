import Slider from "react-slick";
import React from 'react';

// CSS
import Styles from './SliderHome.module.css';

// Images
import LogoCabrales from '../../../assets/imgs/Home/CompaniesImgs/Cabrales.png';
import LogoVitalis from '../../../assets/imgs/Home/CompaniesImgs/Vitalis.png';
import LogoTN from '../../../assets/imgs/Home/CompaniesImgs/Tiendanube.png';
import LogoParque from '../../../assets/imgs/Home/CompaniesImgs/Parque.png';
import LogoNino from '../../../assets/imgs/Home/CompaniesImgs/Nino.png';

// Slick carousel
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const SliderHome = () => {
    const settings = {
        centerMode: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        pauseOnHover: false,
        autoplay: true,
        speed: 5500,
        autoplaySpeed: 1,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    
    return (
        <div className={Styles.SliderHomeContainer}>
            <div className={Styles.LogosCompanies}>
                <Slider {...settings} className={Styles.LogosCompaniesSlider} arrows= {false}>
                    <img 
                        className={Styles.CompanyImg1} 
                        src={LogoTN} 
                        alt='Logo Company'
                    />
                    <img 
                        className={Styles.CompanyImg2} 
                        src={LogoParque} 
                        alt='Logo Company' 
                    />
                    <img 
                        className={Styles.CompanyImg3} 
                        src={LogoCabrales} 
                        alt='Logo Company' 
                    />
                    <img 
                        className={Styles.CompanyImg4} 
                        src={LogoNino} 
                        alt='Logo Company'
                    />
                    <img 
                        className={Styles.CompanyImg5} 
                        src={LogoVitalis} 
                        alt='Logo Company'
                    />
                    <img 
                        className={Styles.CompanyImg1} 
                        src={LogoTN} 
                        alt='Logo Company'
                    />
                    <img 
                        className={Styles.CompanyImg2} 
                        src={LogoParque} 
                        alt='Logo Company' 
                    />
                    <img 
                        className={Styles.CompanyImg3} 
                        src={LogoCabrales} 
                        alt='Logo Company' 
                    />
                    <img 
                        className={Styles.CompanyImg4} 
                        src={LogoNino} 
                        alt='Logo Company'
                    />
                    <img 
                        className={Styles.CompanyImg5} 
                        src={LogoVitalis} 
                        alt='Logo Company'
                    />
                </Slider>
            </div>
        </div>
    )
}

export default SliderHome
