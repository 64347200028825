import React from 'react';

// CSS
import Styles from './Technologies.module.css';

// Component
import TechnologiesItem from './TechnologiesItem/TechnologiesItem';

const Technologies = () => {
    return (
        <div className={Styles.TechnologiesLogosContainer}>
            <TechnologiesItem 
                duration={"1000"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FAmazon.png?alt=media&token=379755b1-d4f7-443c-bed7-60f1ff953623'
                }
            />
            <TechnologiesItem
                duration={"1000"} 
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FGoogleCloud.png?alt=media&token=5c2524ca-2a37-41bd-a4c6-47c69b145c7c'
                }
            />
            <TechnologiesItem
                duration={"1000"} 
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FCs.png?alt=media&token=dcb10f4f-edea-41c5-bc9a-965ee60e5798'
                }
            />
            <TechnologiesItem 
                duration={"1000"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FJs.png?alt=media&token=4c4e5c56-74b8-41d6-ad13-917fe3e64299'
                }
            />
            <TechnologiesItem 
                duration={"1000"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FPython.png?alt=media&token=b4288ef4-9eff-4e67-bdcb-98908fc534ee'
                }
            />
            <TechnologiesItem 
                duration={"1000"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FJava.png?alt=media&token=4526f100-a84b-413c-a3bf-56df037f7573'
                }
            />
            <TechnologiesItem 
                duration={"1500"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FApple.png?alt=media&token=58ab1c85-68d1-483d-93bc-078cc66ff441'
                }
            />
            <TechnologiesItem
                duration={"1500"} 
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FAndroid.png?alt=media&token=392ada4e-c7ee-4c70-98d0-3bcf4dec5b88'
                }
            />
            <TechnologiesItem 
                duration={"1500"} 
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FFlutter.png?alt=media&token=8c14c526-58be-478e-9dc8-0dfc290e82f1'
                }
            />
            <TechnologiesItem 
                duration={"1500"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FMySql.png?alt=media&token=9835cce1-5514-4c7c-b865-39fc914191ae'
                }
            />
            <TechnologiesItem 
                duration={"1500"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FSql.png?alt=media&token=b79404d1-6913-47c4-831b-fd6883888236'
                }
            />
            <TechnologiesItem 
                duration={"1500"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FEl.png?alt=media&token=6436fb52-f302-48a6-b5e3-df5421071e5e'
                }
            />
            <TechnologiesItem 
                duration={"2000"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FCFirestore.png?alt=media&token=aee7d1d4-5ede-4e49-b1d0-1819c5357d0a'
                }
            />
            <TechnologiesItem 
                duration={"2000"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FMongo.png?alt=media&token=340a8e01-8dd3-4328-a45d-ba5ec0f81e4e'
                }
            />
            <TechnologiesItem 
                duration={"2000"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FReact.png?alt=media&token=950935ee-a4ca-4993-9761-f89acad7c821'
                }
            />
            <TechnologiesItem 
                duration={"2000"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FVue.png?alt=media&token=edd7b663-98d8-41ba-a894-050222cd0706'
                }
            />
            <TechnologiesItem 
                duration={"2000"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FHtml.png?alt=media&token=be38b80d-fa31-4aca-aa37-0cf1f0389520'
                }
            />
            <TechnologiesItem 
                duration={"2000"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FCss.png?alt=media&token=d9a83386-250d-4ebc-9126-35ec46a0fad8'
                }
            />
            <TechnologiesItem 
                duration={"2200"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FAngular.png?alt=media&token=c1d38d73-f63e-42f2-aabd-017e9ecf4136'
                }
            />
            <TechnologiesItem 
                duration={"2200"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FNode.png?alt=media&token=72115e52-8b45-4a53-8815-55fbb9f3d328'
                }
            />
            <TechnologiesItem 
                duration={"2200"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FFirebase.png?alt=media&token=b527efdd-2998-40bf-b3d6-4f066473814b'
                }
            />
            <TechnologiesItem 
                duration={"2200"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FAspNet.png?alt=media&token=7dac040a-f58d-4700-abdd-8d4180b43fd3'
                }
            />
            <TechnologiesItem 
                duration={"2200"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FKeras.png?alt=media&token=637c357f-fad0-43bd-9cc0-3aabc3619ae7'
                }
            />
            <TechnologiesItem 
                duration={"2200"}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FTechnologies%2FTensor.png?alt=media&token=2fd2895d-eea4-48fd-880d-29a5aaae8506'
                }
            />
        </div>
    )
}

export default Technologies
