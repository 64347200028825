import React from 'react';

// CSS
import Styles from './CardsAboutUs.module.css';

const CardsAboutUs = (props) => {
    return (
        <div data-aos="fade-down" data-aos-duration={props.duration} className={Styles.CardAboutUsContainer}>
            <img src={props.src} alt="Logo About Us"/>
            <h2>{props.title}</h2>
            <p>{props.description}</p>
        </div>
    )
}

export default CardsAboutUs
