import React from 'react';

// CSS
import Styles from './AboutUs.module.css';

// Components
import PeopleAndJobSection from '../../components/AboutUs/PeopleAndJobSection/PeopleAndJobSection';
import CardsAboutUs from '../../components/AboutUs/CardsAboutUs/CardsAboutUs';
import { Link } from 'react-router-dom';

const AboutUs = () => {
    return (
        <>
            <section className={Styles.HeroAboutUs}>
                <div className={Styles.HeroAboutUsTexts}>
                    <h1
                        data-aos="fade-right" 
                        data-aos-duration="2000"
                    >About Us</h1>
                    <p 
                        data-aos="fade-up" 
                        data-aos-duration="2000"
                    >
We are a software development company that provides customized solutions to third parties and develops jointly with traditional companies. Our tailor-made solutions allow enterprises to bring their ideas to life as quickly and efficiently as possible. We are committed to potentiate organization’s performance by creating unique software that can be used not only to benefit individuals, families, industries, but also the entire business landscape.                    </p>
                </div>
            </section>
            <section className={Styles.HistoryAndValuesContainer}>
                <div className={Styles.HistoryAndValuesPresentation}>
                    <h1>Our history and values</h1>
                    <p 
                    >
                        Our mission is to help transform traditional, both national and foreign companies, into the industries of tomorrow. Among the fields in which we have intervened in, we can mention the tourism, gastronomic, entertainment, oil and gas, electronic commerce, blockchain & cryptocurrencies and digital graphics services industries.
                    </p>
                </div>
                <div className={Styles.CardsAboutUsContainer}>
                    <CardsAboutUs
                        src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FCards%2FChield.png?alt=media&token=44e7624b-d0da-4f29-91fd-c8ad99051ebf'}
                        title={"Quality"}
                        description={
                            "We don't stop until the product we deliver is the best as it can be. We do not settle for less."
                        }
                        duration={"1000"}
                    />
                    <CardsAboutUs
                        src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FCards%2Fps_justice.png?alt=media&token=89177582-fbd0-4c32-b208-d4ad9fe6c00b'}
                        title={
                            "Trust"
                        }
                        description={
                            "From day one we achieve our goals with responsibility, honesty and absolute reliability."
                        }
                        duration={"1000"}
                    />
                    <CardsAboutUs
                        src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FCards%2Fic_outline-handshake.png?alt=media&token=72101a73-90d7-4388-bf7b-eff850d4207e'}
                        title={
                            "Commitment"
                        }
                        description={
                            "We are 100% involved in each one of the new challenges we face, giving the best of our potential."
                        }
                        duration={"1000"}
                    />
                    <CardsAboutUs
                        src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FCards%2Ffluent_people-team-24-regular.png?alt=media&token=20726df1-0085-4666-9e22-f2c99487300d'}
                        title={
                            "Teamwork"
                        }
                        description={
                            "All our resources work aligned in order to achieve our goals. We all look for the same results, so we focus synergically on them. "
                        }
                        duration={"1500"}
                    />
                    <CardsAboutUs
                        src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FCards%2FLamp.png?alt=media&token=1faa6f18-934b-4801-9f3e-148468c35bf2'}
                        title={
                            "Creativity"
                        }
                        description={
                            "We are of the opinion that inventiveness and originality should be incorporated at every level of development, challenging our teams to look beyond what has been already created, daring to transcend their comfort zones."
                        }
                        duration={"1500"}
                    />
                    <div data-aos="fade-down" data-aos-duration="1500" className={Styles.SimplicityCard}>
                        <img src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FCards%2FStatus.png?alt=media&token=c6dafc2a-81b1-4350-aeef-f60275a7351e'} alt="Logo About Us"/>
                        <h2>Simplicity</h2>
                        <p> We believe that less is more. As Leonardo Da Vinci said a while ago: <i>“...Simplicity is the ultimate sophistication...”</i></p>
                    </div>
                </div>
            </section>
            <main className={Styles.OurTeamContainer}>
                <h1>Our Team</h1>
                <p>We have developed our activities focusing on training teams of developers, using agile work methodologies, prioritizing individuals and interactions, functional software, collaboration with clients and quick response to change.</p>
                <PeopleAndJobSection/>
            </main>
            <section className={Styles.JoinUsContainer}>
                <div className={Styles.JoinUsContactContainer}>
                    <h1>Do you want to be part of our team? <br className={Styles.JoinUsBr}/>We are hiring!</h1>
                    <Link to='/contact' className={Styles.ButtonContactJoinUs}>Join us</Link>
                </div>
            </section>
        </>
    )
}

export default AboutUs
