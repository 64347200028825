import React from 'react';

// CSS
import Styles from './PeopleAndJobSection.module.css';

// Components
import PeopleItem from "../PeopleItem/PeopleItem";

const PeopleAndJobSection = () => {
    return (
        <div className={Styles.PeopleAndJobContainer}>
            <h2>Jobs and People</h2>
            <div className={Styles.PeopleContainer}>
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Rafael Gijón"}
                    job={"Co-Founder"}
                />
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Nicolás D’auro"}
                    job={"Co-Founder"}
                />
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Pablo Sahakian"}
                    job={"Co-Founder"}
                />
                    <PeopleItem
                            src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                            name={"Valentina Cecchi"}
                            job={"Chief Operating Officer"}
                    />
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Pablo Spennato"}
                    job={"Chief Technology Officer "}
                />
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Federico Cisneros"}
                    job={"Sr. Developer"}
                />
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Joaquín Amenta"}
                    job={"Sr. Developer"}
                />
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Ezequiel Salagoity"}
                    job={"Sr. Developer"}
                />
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Gonzalo Ávalos"}
                    job={"Sr. Developer"}
                />
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Manuel Honores"}
                    job={"SSr. Developer"}
                />
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Carlos Cabral"}
                    job={"Jr. Developer"}
                />
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Ignacio Garate"}
                    job={"Jr. Developer"}
                />
                <PeopleItem
                    src={'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FAbout%2FProfiles%2FEllipse.png?alt=media&token=4a487617-3508-4bd5-9121-162891391fdb'}
                    name={"Josefina Maldonado"}
                    job={"UX-UI Designer"}
                />
            </div>
        </div>
    )
}

export default PeopleAndJobSection
