import React from 'react';

// CSS
import Styles from './CardHome.module.css';

const CardHome = (props) => {
    return (
        <div data-aos="flip-left" data-aos-duration={props.duration} className={Styles.CardHomeContainer}>
            <img src={props.src} alt='Icon Card Home'/>
            <h2>{props.title}</h2>
            <p>{props.description}</p>
        </div>
    )
}

export default CardHome
