import React from 'react';

// CSS
import Styles from './Home.module.css';

// Components
import HowWeWorkItem from '../../components/Home/HowWeWorkItem/HowWeWorkItem';
import Technologies from '../../components/Home/Technologies/Technologies';
import SliderHome from '../../components/Sliders/SliderHome/SliderHome';
import CardHome from '../../components/Cards/CardHome/CardHome';

// Images
import ImageHWW1 from '../../assets/imgs/Home/HowWeWork/HWWImage1.png';
import ImageHWW2 from '../../assets/imgs/Home/HowWeWork/HWWImage2.png';
import ImageHWW3 from '../../assets/imgs/Home/HowWeWork/HWWImage3.png';
import ImageHWW4 from '../../assets/imgs/Home/HowWeWork/HWWImage4.png';
import ImageHWW5 from '../../assets/imgs/Home/HowWeWork/HWWImage5.png';
import ImageCar1 from '../../assets/imgs/Home/ImageCard1.png';
import ImageCar2 from '../../assets/imgs/Home/ImageCard2.png';
import ImageCar3 from '../../assets/imgs/Home/ImageCard3.png';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className={Styles.HomeContainer}>
            <section className={Styles.HeroSectionHome}>
                <div className={Styles.ContactHomeTop}>
                    <h1>We build tailor-made software</h1>
                    <p 
                        data-aos="fade-up" 
                        data-aos-duration="2000"
                    >
                        Join us on this trip and discover how we generate new technological experiences. 
                        <span className={Styles.ContactSpecialText}> Contact us and transform your ideas into solutions</span>
                    </p>
                </div>
                <div className={Styles.SliderCompaniesSection}>
                    <h2>Companies that trust our work</h2>
                    <SliderHome/>
                </div>
            </section>
            <section className={Styles.WhatWeDoContainer}>
                <div className={Styles.WhatWeDoContainerTexts}>
                    <h1>Our Solutions</h1>
                    <p>We provide our partners with tailor-made software services that adapt logically to their project’s requirements and business needs by adopting the agile method of software development. We seek to elaborate dependable, feasible and efficient solutions.</p>
                </div>
                <div className={Styles.WhatWeDoContainerCards}>
                    <CardHome 
                        src={ImageCar1}
                        title={
                            "Business Analysis"
                        } 
                        description={
                            "We stand for communication as the key to best understand our customer's needs. Our team can analyze your project´s functionality, business logic, software architecture and future user scenarios. We’ll take care of your problem’s solution, so you can focus on the big picture."
                        }
                        duration={"500"}
                    />
                    <CardHome 
                        src={ImageCar2}
                        title={
                            "Product Development"
                        } 
                        description={
                            "We apply best practice processes and development techniques as groundwork for framing leading-edge software solutions using agile methodologies. Our customers get the best results every time because of our passion, expertise, and experience."
                        }
                        duration={"1500"}
                    />
                    <CardHome 
                        src={ImageCar3}
                        title={
                            "Quality Assurance"
                        } 
                        description={
                            "We believe in getting it right every time -that's why our QA and Development teams work independently but synergically.  Rigorous QA checks take place throughout all our projects."
                        }
                        duration={"3000"}
                    />
                </div>
            </section>
            <main className={Styles.HowWeWorkContainer}>
                <div className={Styles.HWWInfo}>
                    <div className={Styles.HWWItems}>
                        <div className={Styles.HWWItemsTitle}>
                            <h1>Our Guidelines</h1>
                        </div>
                        <HowWeWorkItem
                            src={ImageHWW1}
                            title={
                                "Business Analysis"
                            }
                            description={
                                "Our team analyzes your idea´s functionality, business logic, software architecture and future user scenarios."
                            }
                            duration={"1000"}
                        />
                        <HowWeWorkItem
                            src={ImageHWW2}
                            title={
                                "UI - UX"
                            }
                            description={
                                "We design intuitive interfaces for a quick adaptation of the system, showing the best of your company."
                            }
                            duration={"1500"}
                        />
                        <HowWeWorkItem
                            src={ImageHWW3}
                            title={
                                "Agile Methodologies"
                            }
                            description={
                                "Allows us to focus on our customers needs, detecting issues faster, increasing frequency of collaboration and feedback, minimizing resources by reducing waste and efficiently overseeing our projects."
                            }
                            duration={"2000"}
                        />
                        <HowWeWorkItem
                            src={ImageHWW4}
                            title={
                                "Cloud Architecture"
                            }
                            description={
                                "Adopting this method we accelerate the delivery of new projects, operate with transparency in order to cut costs and prevent data breaches and offer scalability according to the requirements of your business."
                            }
                            duration={"2500"}
                        />
                        <HowWeWorkItem
                            src={ImageHWW5}
                            title={
                                "Multidisciplinary teams"
                            }
                            description={
                                "Team collaboration in order to create new avenues for SaaS, increasing the project´s quality and reducing development time, mistakes, and integration. "
                            }
                            duration={"3000"}
                        />
                    </div>
                </div>
                <div className={Styles.HWWImage}>
                    <img 
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/apeiron-web.appspot.com/o/Images%2FHome%2FHWWImage.png?alt=media&token=0a4d1472-cd8f-4467-8535-4eef3c63c39e'
                        } 
                        alt="How we work" 
                    />
                </div>
            </main>
            <section className={Styles.TechnologiesContainer}>
                <h1>Technologies we use</h1>
                <Technologies/>
            </section>
            <section className={Styles.LetsStart}>
                <div data-aos="fade-right" data-aos-duration="1000" className={Styles.LetsStartContainer}>
                    <h1>Are you ready to start working<br className={Styles.LetsStartContainerBr}/> together?</h1>
                    <Link className={Styles.ButtonContact} to='/contact'>Contact us</Link>
                </div>
            </section>
        </div>
    )
}

export default Home
