import React from 'react';

// CSS
import Styles from './HWWProcess.module.css';

const HWWProcess = (props) => {
    return (
        <div className={Styles.HWWProcessItem}>
            <img src={props.src} alt="Img How we work"/>
            <div className={Styles.HWWProcessItemTexts}>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
            </div>
        </div>
    )
}

export default HWWProcess
